@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap");

.navContainer {
  z-index: 6;
  position: sticky !important;
  top: 0;
  width: 100%;
  background-color: #00809E;
  display: flex;
  flex-direction: row;
  font-family: "Ag", sans-serif;
  font-weight: bold;
  font-size: 15px;
}

.logoImg {
  height: 50px;
  width: 50px;
  margin-right: 10px;
}

.dropdownItemSelected {
  color: #0D86A2 !important;
  font-weight: bold !important;
}

.links {
  display: flex;
  flex-direction: row;
}
.rightSide {
  flex-direction: row;
  flex: 1;
  display: flex;
  align-items: center;
}

.searchDiv {
  background-color: #ffffff;
  border-color: #ffffff;
  border-radius: 5px;
  height: 40px;
  max-width: 250px;
  margin: 5px;
}

  /* Styles to make the NavLink look like a button */
  /* all !important are required, hover also required even though it appears like a duplicate*/
.button {
  background-color: #BAE3ED;
  color: #0D86A2; 
  display: flex;
  align-items: center;
  padding: 0px 15px;
  margin: 3px;
  height: 50px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Ag", sans-serif;
  font-weight: bold;
  font-size: 15px;
}

.button:hover {
  filter: brightness(90%);
  text-decoration: none;
}

.activeButton {
  background-color: #FFFFFF !important;
  color: #0D86A2 !important; 
}

.activeButton:hover {
  color: #0D86A2; 
}

.buttonDark {
  background-color:#00809E;
  color:#FFFFFF;
}

.buttonDark:hover {
  color:#FFFFFF;
}

.buttonLight {
  background-color: #BAE3ED;
  color: #0D86A2; 
}

.buttonLight:hover {
  color: #0D86A2; 
}