@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap");

a {
  color: #00809e;
}

.globalFrame {
  font-size: 1.88vw;
  /* border: 1px;
    border-style: solid;
    border-color: black; */
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  font-family: "Roboto", sans-serif;
  height: 100%;
  padding-top: 5%;
  padding-bottom: 10%;
}

.text {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.logo {
  display: flex;
  flex-direction: column;
}

.logo img {
  width: 30.1vh;
  height: auto;
  margin: 10% 0%;
  align-self: center;
}

.buttons{
  display: flex;
  flex-direction: column;
  padding-top: 2%;
}

.buttons Button{
  color: white;
  text-align: center;
  font-size: 1.8vh;
  background-color: #06738f;
  border-radius: 10px;
  padding: 1.5% 2.2%;
  border: none;
  text-transform: none;
  margin: 4% 0%;
  width: 50%;
  align-self: center;
}

.buttons Button:hover{
  background-color: #004252;
}
