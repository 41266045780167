.bottom-popup {
    position: absolute;
    bottom:0;
    left: 0;
    width: 100vw;
    height: 30vh;
    background-color: #1c2429;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    outline: 0;
    border-radius: 10px 10px 0 0;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100svh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.topRow,
.bottomRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2vh 1vh;
}
.openButton {
    background-color: rgb(181, 18, 18);
    color:white;
    width: 100%;
    text-align: center;
}
.continueButton {
    background-color: rgb(0, 0, 0);
    color:white
}

.openButton,
.continueButton {
    border: none;
    border-radius: 25px;
    width: 25vw;
    height: 5vh;
}

.openButton:hover,
.continueButton:hover {
    background-color: #45a049;
}

.bottomContent{
    width: 100%;
    margin-top: 1vh;
    padding: 1vw;
    margin-bottom: 5svh;
}
.logoImg{
    height: 40px;
  width: 50px;
  margin-right: 1vw;
}

.browserLogoImg{
    height: 50px;
    width: 50px;
    margin-right: 1vw;
}
.logoAndName {
    display: table;
    width: 50vw;
    color: white;
}

.logoAndName img {
    display: table-cell;
    vertical-align: middle;
    width: 50px;
}
.logoAndName p {
    display: table-cell;
    vertical-align: middle;
    width: 70%;
}