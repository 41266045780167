@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap");

.banderole {
  height: 10vh;
  width: 100%;
}

.banderole p {
  color: black;
  font-weight: bold;
  position: relative;
  text-align: center;
  font-size: 3.4vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active, .accordion:hover {
  background-color: #ccc;
}

/* Style the accordion panel. Note: hidden by default */
.panel {
  padding: 0 18px;
  background-color: white;
  display: none;
  overflow: hidden;
}

.buttonPosition {
  position: relative;
  margin-top: 50px;
  margin-bottom: 50px;
  /* margin-left: 47%; */
  /* left: 50%; */
  /* -ms-transform: translate(-50%, -50%); */
  /* transform: translate(-50%, -50%); */
}