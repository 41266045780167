@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap");

.cardBody {
	background-color: #f1f1f1;
}

.dropbtn {
  background-color: #9D9D9D;
  color: white;
  padding: 8px;
  padding-left: 60px;
  padding-right: 60px;
  text-align: center;
}

.dropbtnblue {
  background-color: #01647B;
  color: white;
  padding: 8px;
  padding-left: 60px;
  padding-right: 60px;
  cursor: pointer;
  min-width: 120px;
  text-align: center;
}

.dropdownContent {
  display: flex;
  flex-direction: column;
  padding: 12px;
  background-color: #f9f9f9;
  z-index: 1;
  cursor: pointer;
  min-width: 120px;
  text-align: center;
}

.dropdownContent div:hover {
  background-color: #01647B;
  color: white;
}

.sideBar {
  float: left;
  width: 20%;
  height: 100%;
  display: inline-block;
  background-color: #ffffff;
}

.accordion {
  float: left;
  width: 100%;
  padding: 0 50px 0 20px;
  display: inline-block;
}


.listItemTextPrimary {
  width: 20%;
  flex-grow:  0;
}

.listItemTextSecondary {
  width: 80%;
}


.cardImageCounter {
  float: right;
}

.cardImageLogo {
  float: right;
  margin-left:  5px;
  padding-right: 5px;
}

.tableRow:hover {
  filter: brightness(90%);
}

.darkerRow {
  background-color: #cbcbcb;
}
