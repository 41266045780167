.common {
    border: none;
    outline: none;
}

.small {
    font-size: 1.637vh;
    border-radius: 8px;
    padding: 8px 15px;
}

.medium {
    font-size: 2vh;
    border-radius: 15px;
    padding: 15px 25px
}

.large {
    font-size: 3vh;
    border-radius: 25px;
    padding: 20px 35px;
}


.primary {
    color: white;
    background-color: #06738f;
}
.primary:hover{
    background-color: #004252;
}
  

.secondary {
    color: white;
    background-color:#ff0033;
}