@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap");

a {
  color: #00809e;
}

.globalFrame {
  font-size: 1.88vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  font-family: "Roboto", sans-serif;
}

.centeringFlex {
  justify-content: center;
  display: flex;
}

.globalFrame h1 {
  text-align: center;
  font-size: 5vh;
}

.globalFrame h2 {
  font-weight: normal;
}

.globalFrame p {
  line-height: 1.8;
  font-size: 1.8vh;
}

.banderole {
  background: url(../../../assets/images/about/heart-shaped-cutting-board.png)
    center center/cover no-repeat;
  box-shadow: inset 0 0 0 250.94vh rgba(0, 0, 0, 0.4);
  position: relative;
  height: 60vh;
  width: 100%;
}

.banderolePhotoOverlay {
  background-color: black;
  opacity: 20%;
  position: absolute;
  height: 100%;
  width: 100%;
}

.banderoleBox {
  position: absolute;
  right: 20px;
  width: 40%;
  height: 60vh;
  text-align: center;
}
.banderole p {
  color: white;
  position: relative;
  font-style: italic;
  font-size: 2.8vh;
  top: 10%;
}

.banderole button {
  position: relative;
  font-size: 2.8vh;
  padding: 10px;
  top: 10%;
  z-index: 5;
  color: white;
  background-color: #01647b;
  text-align: center;
  border-radius: 8px;
  border: none;
  text-transform: none;
}

.ourTeam {
  padding: 2% 0%;
  padding-top: 30px;
  text-decoration: underline 2px;
  text-underline-position: under;
}

.historyDesc {
  text-indent: 5%;
  margin-bottom: 10px;
  text-align: justify;
}
.historyTitle {
  text-align: center;
  font-weight: bold;
}
.cards {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.teamMember {
  padding: 2% 2%;
  width: 90%;
  height: 100%;
}

.card {
  border-radius: 36px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #f1f1f1;
  height: 355px;
}
.headshot {
  padding: 0% 1%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.profilePic {
  width: 160px;
  height: 160px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  margin: 25px 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.imageStyle {
  display: block;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100%;
}

.flags {
  margin: 20px 50px;
}

.flags img {
  height: 21.5px;
  width: 30px;
  margin: 0 2px 0;
}

.role {
  margin: 20px;
  padding-bottom: 20px;
}

.mentorscard {
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #f1f1f1;
  height: 400px;
}
.description {
  padding: 0%;
}

.description p {
  padding-top: 1%;
}

.programs img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width:200px
  /* width: 15%; */
  /* height: 10% */
}
.dpStudents {
  padding: 50px 50px;
}

.dpStudents h1 {
  text-decoration: underline 2px;
  text-underline-position: under;
  margin: 50px;
}

.dpStudents h3 {
  text-align: center;
}

.paddingBottom20px {
  padding-bottom: 20px;
}

.dpStudentsRow {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.dpStudentsCol {
  text-align: center;
}

.dpStudentsCol h3 {
  /* padding: 2% 0%; */
  text-align: center;
}

.collaborators {
  padding-top: 5%;
}

.collaboratorsImgAndDesc {
  display: flex;
  padding-left: 3%;
  padding-top: 3%;
}

.collaboratorsImg img {
  width: 52.7vh;
  height: auto;
}

.collaboratorsDesc {
  padding: 0% 2%;
}

.prevCollab {
  padding-top: 6%;
}

.prevCollabRow {
  display: flex;
  padding: 0% 2%;
}

.prevCollabRow h2 {
  text-align: center;
}

.prevCollabRow p {
  padding: 0% 12%;
}

.prevCollabCol {
  display: flex;
  flex-direction: column;
}

.join {
  display: flex;
  justify-content: center;
  margin: 50px;
}

.joinImg img {
  width: 50.1vh;
  height: auto;
}

.joinDesc {
  width: 100%;
}

.joinText {
  width: 50% !important;
  margin: auto;
}

.joinDesc h1 {
  margin-right: 25px;
}

/* Responsive layout for mobile or smaller screens */
@media (max-width: 1280px) {
  .banderoleBox{
    width: 50%;
  }

  .banderole i {
    top: 50%;
  }

  .banderole p {
    font-size: 17px;
    padding: 8px;
  }

  .banderole button {
    font-size: 17px;
  }

  .teamMember {
    flex-direction: column;
  }

  .headshot {
    position: relative;
    overflow: hidden;
  }

  .description {
    padding-top: 5%;
  }

  .dpStudentsRow {
    flex-direction: column;
  }

  .collaboratorsImgAndDesc {
    flex-direction: column;
  }

  .collaboratorsImg {
    align-self: center;
  }

  .collaboratorsDesc {
    padding-top: 5%;
  }

  .prevCollabRow {
    flex-direction: column;
  }

  .join {
    flex-direction: column;
    padding-left: 0%;
    justify-content: center;
  }

  .joinImg {
    align-self: center;
  }

  .joinDesc {
    padding-top: 5%;
    padding-left: 0%;
    padding-right: 0%;
    text-align: center;
  }

  .joinDesc h1 {
    text-align: center;
  }
}

@media (max-width: 820px) {
  .banderole p {
    font-size: 16px;
    padding: 5px;
  }

  .banderole button {
    font-size: 16px;
  }
}

@media (max-width: 620px) {
  .banderoleBox{
    width: 90%;
  }
  
  .banderole p {
    font-size: 18px;
    padding: 3px;
  }

  .banderole button {
    font-size: 18px;
  }
}
