@import url("https://fonts.googleapis.com/css2?family=Ag&display=swap");

.container {
  background-color: #FFFFFF;
  padding-top: 60px;
  font-size: 17px;
  font-weight: 200;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.planContainer {
  padding: 30px;
}

.planTitle{
  color: #00809E;
  font-family: "Ag", sans-serif;
  font-size: 60px;
  font-weight: 600;
}

.planPrice {
  font-weight: 500;
}

.planLight{
  color: #888888;
}

.button {
  background-color:#00809E;
  color:#FFFFFF !important;
  padding: 10px 25px;
  margin: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 5px;
  font-family: "Ag", sans-serif;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
}

.circleLight {
  background-color: rgba(0, 128, 158, 0.12);
  border-radius: 50%;
  display: inline-block;
  position: absolute;
}

.circleDark {
  background-color: rgba(0, 128, 158, 0.4);
  border-radius: 50%;
  display: inline-block;
  position: absolute;
}

@media (max-width: 768px) {
  .circleDark {
    display: none;
  }
}