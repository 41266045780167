.globalFrame{
    font-size: 1.637vh;
    display: flex;
    flex-direction: row;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    /* margin-top: 10%; */
}
.title{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 2%;
}

.info{
    align-self: center;
}

.clickable{
    color: black
}

.desc{
    text-align: center;
    padding: 3% 5%;
}

.itemDesc{
    text-align: left;
    padding: 0% 5%;
}

.itemDesc h5{
    text-align: left;
    font-weight: bold;
}
.itemDesc p{
    text-align: justify;
    white-space: pre-wrap;
}
.button{
    border-radius: 20px;
    font-size: 16px;
    background-color: '#F1F1F1';
    margin-left: 5px;
}
.title{
    margin-top: 3%;
}

.title h2{
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    
}

.title h4{
    font-size: 18px;
    font-weight: normal;
    text-align: center;
}
.shareIcon{
    height: 20px;
    width: 20px;
    margin-left: 1%;
}
