@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap');

/* used */

.icon{
    width:50px;
    height:50px;
    margin-left: 1%;
}

.storeTitle{
    flex-direction: row;
    font-family: 'Roboto', sans-serif;
    font-size: 2.5rem;
    text-align: center;
}

.searchBar {
    width: 100%;
    margin-bottom: '10px';
}

.itemList {
    font-size: 3vh;
}


.activeTab {
    background-color: #ffffff;
    text-decoration: none;
    color: black
}

.activeTab:hover {
    background-color: #ffffff;
    text-decoration: none;
    cursor: pointer;
    text-decoration: none;
    filter: brightness(90%);
    color: black
}

.inactiveTab {
    background-color: #bae3ed;
    text-decoration: none;
    color: black
}

.inactiveTab:hover {
    background-color: #bae3ed;
    text-decoration: none;
    cursor: pointer;
    text-decoration: none;
    filter: brightness(90%);
    color: black
}

a {
    color: #00809e;
}