.storeImage{
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

.buttonStyleHover {
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.buttonStyleHover:hover {
  background-color: #f1f1f1;
  filter: brightness(90%);
  color: black;
  text-decoration: none;
}

.storeImage img{
    margin: auto;
    display: block;
    width:50%;
    margin-top: 3%;
    border-radius: 10px;
}
.globalFrame{
  font-size: 1.637vh;
  display: flex;
  flex-direction: row;
  font-family: 'Roboto', sans-serif;
  /* margin-top: 10%; */
}
.title{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 2%;
}

.info{
  align-self: center;
}

.clickable{
  color: black
}

.desc{
  text-align: center;
  padding: 3% 5%;
}

.itemDesc{
  text-align: left;
  padding: 3% 5%;
}

.itemDesc h5{
  text-align: left;
  font-weight: bold;
}
.itemDesc p{
  text-align: justify;
  white-space: pre-wrap;
}
.button{
  border-radius: 20px;
  font-size: 16px;
  background-color: '#F1F1F1';
  margin-left: 5px;
}
.buttons{
  text-align: center;
}

.buttons button{
  background-color: black;
  border: 2px solid black;
  color: white;
  padding: 2% 4.5%;
  text-align: center;
  text-decoration: none;
  font-size: 1.637vh;
  margin: 4px 10px 20px;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
  opacity: 0.85;
  transition: 0.3s;
}
.buttons button.view{
  background-color: #00809E;
  border: 2px solid #00809E;
  color: #F1F1F1;
}
.title{
  margin-top: 3%;
}

.title h2{
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  
}

.title h4{
  font-size: 18px;
  font-weight: normal;
  text-align: center;
}
.shareIcon{
  height: 20px;
  width: 20px;
  margin-left: 1%;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.singleListImage {
  max-width: 400px;
  max-height: 300px;
  object-fit: contain;
  border-radius: 10px;
}