@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ag&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Garamond&display=swap");


.container {
  background-color: #FFFFFF;
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 100%;
  margin: 0;
  font-size: 17px;
  font-weight: 200;
  display: flex;
  flex-direction: column;
  position: relative !important;
}

.centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.button {
  cursor: pointer;
  background-color:#00809E;
  overflow: hidden;
  color:#FFFFFF !important;
  padding: 10px 25px;
  margin: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 5px;
  font-family: "Ag", sans-serif;
  font-weight: bold;
  font-size: 20px;
}

.homeLogo {
    height: 300px;
    width: 300px;
}

.trouble{
    color: #00809E;
    font-family: "Ag", sans-serif;
    font-size: 65px;
    font-weight: 500;
}

.row {
    padding: 60px 0;
    justify-content: center;
}

.isItTitle{
    color: #00809E;
    font-family: "Ag", sans-serif;
    font-size: 35px;
    font-weight: 600;
    margin: 0 auto;
}

.circleLight {
    background-color: rgba(0, 128, 158, 0.12);
    border-radius: 50%;
    display: inline-block;
    position: absolute;
}

.circleDark {
    background-color: rgba(0, 128, 158, 0.4);
    border-radius: 50%;
    display: inline-block;
    position: absolute;
}

.typist-cursor {
    display: none;
}

.sustainableTitle{
    color: #00809E;
    font-family: "Ag", sans-serif;
    font-size: min(80px, 8vw);
    font-weight: 600;
}

.sustainabilityTooltip {
    font-size: 17px;
    padding: 5px;
    line-height: 1.5; 
}

.journeysContainer {
    width: 60%;
}

.journeyDivider{
    background-color: #00809E;
    height: 2px;
    min-width: 100%;
    margin: 80px;
}

.journeyTitle{
    color: #00809E;
    font-family: "Ag", sans-serif;
    font-size: 35px;
    font-weight: 600;
    margin: 10px;
}

.journeySubtitle{
    color: #00809E;
    font-family: "Ag", sans-serif;
    font-size: 17px;
    font-weight: 300;
    margin: 10px;
    text-align: center;
}

.mcgill {
    font-family: "Garamond";
    font-size: 45px;
    font-weight: 600;
    color: #ED1B2F;
    margin: 10px;
}


@media (max-width: 768px) {
    .circleDark {
        display: none;
    }

    .journeysContainer {
        width: 90%;
    }
}

