@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap');

:root {
    --primary-color: #00809E;
}

body{
    overflow-x: hidden;
}

a {
    cursor: pointer;
    color: black;
}

a:hover {
    text-decoration: none !important;
}

/* Uncomment to show div lines */
.globalFrame{
    font-size: 1.637vh;
    border: 1px;
    /* border-style: solid;
    border-color: black; */
    display: flex;
    flex-direction: row;
    font-family: 'Roboto', sans-serif;
    margin: 2%;
}


/* Uncomment to show div lines */
/* .globalFrame div{
    border: 0.5px;
    border-style: solid;
    border-color: black;
} */

.globalFrame h1{
    font-size: 4vh;
    color: #131313;
    text-align: center;
    margin-right: 15px;
}

.globalFrame h2{
    font-weight: normal;
    font-weight: bold;
}

.globalFrame p{
    line-height: 1.8;
    font-size: 1.7vh;
    color: #6e6e6e;
}

.searchBar {
    width: 100%
}

.leftSide {
    display: flex;
    flex-direction: column;
    width: 60%;
}

.itemList h1{
    font-size: 3vh;
    padding-top: 5%;
}

.rightSide {
    display: flex;
    flex-direction: column;
    width: 40%;
    margin-left:10px; 
}

.storeImage img{
    margin: auto;
    display: block;
    width:50%;
    border-radius: 10px;
}

.desc{
    text-align: center;
    padding: 3% 5%;
}
.descri{
    white-space: pre-wrap;
}
.clickable{
    color: black
}

.clickable :hover{
    color: #00809e
}

.itemDesc{
    text-align: center;
    padding: 3% 5%;
}

.icon{
    width:50px;
    height:50px;
    margin-left: 1%;
}

.forumButtons{
    text-align: left;
}

.forumButtons button{
    background-color: black;
    border: 0px solid black;
    color: white;
    padding: 1% 2.5%;
    text-align: center;
    text-decoration: none;
    font-size: 1.637vh;
    margin: 4px 10px 20px;
    border-radius: 10px;
    outline: none;
    cursor: pointer;
    opacity: 0.85;
    transition: 0.3s;
  }

  .forumButtons button.view{
    background-color:  var(--primary-color);
    border: 0px solid  var(--primary-color);
    color: #F1F1F1;
}

.buttons{
    text-align: center;
}

.buttons button{
    background-color:  var(--primary-color);
    border: 2px solid  var(--primary-color);
    color: white;
    padding: 2% 4.5%;
    text-align: center;
    text-decoration: none;
    font-size: 1.637vh;
    margin: 4px 10px 20px;
    border-radius: 10px;
    outline: none;
    cursor: pointer;
    opacity: 0.85;
    transition: 0.3s;
  }


  .buttons button.view{
    background-color:  var(--primary-color);
    border: 2px solid  var(--primary-color);
    color: #F1F1F1;
}
  
  .closed{
    color: red;
  }
  
  .open{
    color: #56B771;    
  }

  /* OrderModal */
.icons{
    display: flex;
    justify-content: center;
  }
  
.icons img{
    width: 18.82vh;
    padding: 0vh 2.5vh;
}

.moreDetails{
    display: flex;
    flex-direction: row;
    padding-top: 8%;
}

/* Includes store phone, payment types and social media links, opening hours and menu */
.additionalInfo{
    display: flex;
    justify-content: space-evenly;
    width: 50%;
}

.collapsibleCardHeader{
    display: flex;
    color: #131313;
    font-size: 20px;
    background-color: #f8f8f9;
    padding: .75rem 1.25rem;
}

.contactAndPayment a{
    font-size: 1.842vh;
    text-decoration: none;
    display: inline;
}

.contactAndPayment p{
    font-size: 1.842vh;
    text-decoration: none;
    display: inline;
}

.contactAndPayment a:hover{
    text-decoration: underline;
}

.contactAndPayment h2{
    display: inline;
    font-size: 22px;
}

.phoneIcon{
    height: 24px;
    width: 24px
}

.paymentsIcons{
    display: grid;
    grid-template-columns: 20% 10% 20%;
    height: 10%;
    justify-items: center;
    margin-bottom: 5%;
}

.socialMediaIcons{
    display: grid;
    grid-template-columns: 16% 16% 16%;
    margin-right: 4%;
    grid-template-rows: 100%;
    height: 30px;
}

.shareIcon{
    height: 20px;
    width: 20px;
}

/* Opening hours */
.hours{
    overflow-y: hidden;
    width: 50%;
    text-align: center;
}

.hours h2{
    font-size: 22px;
}

  /* Responsive layout for mobile or smaller screens */
@media (max-width: 1024px) {

    .globalFrame{
        flex-direction: column;
        align-items: center;
    }

    .nameAndDesc{
        text-align: center;
        max-width: 70%;
    }

    .additionalInfo{
        flex-direction: column;
        height: auto;
    }

    .additionalInfo div{
        margin: 3% auto;
    }

    /* Order Modal */
    .icons{
        display: flex;
        flex-direction: column;
      }

    .icons img{
        padding: 1vh 0vh;
    }

    .moreDetails{
        flex-direction: column;
    }
    
}
.singleStoreImage {
    max-width: 400px;
    max-height: 300px;
    object-fit: contain;
    border-radius: 10px;
}

.centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.storeTitle {
    flex-direction: row;
    font-family: 'Roboto', sans-serif;
    font-size: 2.5rem;
    text-align: center;
}

.buttonStyleHover {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.buttonStyleHover:hover {
    background-color: #f1f1f1;
    filter: brightness(90%);
    text-decoration: none;
}

.hoverEffect:hover {
    filter: brightness(90%);
    text-decoration: none;
}

.hoverEffectWithPointer:hover {
    cursor: pointer;
    text-decoration: none;
    filter: brightness(90%);
}

.activeTab {
    background-color: #ffffff;
    text-decoration: none;
    color: black
}

.activeTab:hover {
    background-color: #ffffff;
    text-decoration: none;
    cursor: pointer;
    text-decoration: none;
    filter: brightness(90%);
    color: black
}

.inactiveTab {
    background-color: #bae3ed;
    text-decoration: none;
    color: black
}

.inactiveTab:hover {
    background-color: #bae3ed;
    text-decoration: none;
    cursor: pointer;
    text-decoration: none;
    filter: brightness(90%);
    color: black
}

.forumPost {
    background-Color: '#f1f1f1';
    padding: '10px';
    margin-bottom: 20;
    border-style: 'solid';
    border-color: 'black';
    border-radius: '10px';
    border-width: '1px';
}

.forumPost:hover {
    filter: brightness(90%);
    text-decoration: none;
}

.forumPostReply {
    background-Color: '#f1f1f1';
    padding: '10px';
    margin-bottom: 20;
    border-style: 'solid';
    border-color: 'black';
    border-radius: '10px';
    border-width: '1px';
}

.forumPostReply:hover {
    filter: brightness(90%);
    text-decoration: none;
}

.storeInfoCategory h5{
    text-align: left;
    font-weight: bold;
}

.addItemButton {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    border-radius: 10px;
    padding: 7px 15px;
    margin: 5px;
    color: white;
}